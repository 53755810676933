import React from 'react'
import { PageProps } from 'gatsby'

import SEO from 'src/components/shared/SEO'

const ConsultationTwo: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Consultation" location={location} />

    <div
      className="octane-ai-quiz "
      data-quiz-id="W4brkR8IjFVdz9qM"
      data-embed-type="fullscreen"
    ></div>
    <script src="https://app.octaneai.com/6d37u9ir3u0odq5c/quiz.js" async defer></script>
  </>
)

export default ConsultationTwo
